.carData,
.ownerData,
.adress,
.polu,
.ins {
	display: flex;
	background: #fff;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	border-radius: 20px;
	border: 2px dotted #ddd;
	margin-bottom: 20px;
}

.datas {
	flex-direction: column;
}

.datas div {
	font-size: 14px;
	font-weight: 400;
	border-bottom: 1px dotted #ddd;
	padding-bottom: 5px;
	margin-bottom: 5px;
	width: 100%;
}


.carData img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: #ddd;
	padding: 3px;
}

.carData_data {
	margin-left: 20px;
}

.car_no {
	font-size: 18px;
	font-weight: 800;
	padding-bottom: 5px;
	border-bottom: 2px dotted #ddd;
	margin-bottom: 10px;
}

.maker,
.Model,
.variant {
	font-size: 14px;
	font-weight: 400;
	border-bottom: 1px dotted #ddd;
	padding-bottom: 5px;
	margin-bottom: 5px;
}