.menu {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #fff;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 9;
}

.close_menu {
    cursor: pointer;
    text-align: right;
    padding-right: 30px;
}

.close_menu img {
    width: 30px;
}

.menu_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user_name {
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 20px;
}

.user_img img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 3px #ddd;
    margin-bottom: 20px;
}

.user_email {
    font-size: 16px;
    margin-bottom: 10px;
}

.signout {
    display: flex;
    align-items: center;
    background: #f7631f;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 1px #aaa;
}

.signout img {
    width: 20px;
    margin-right: 5px;
}