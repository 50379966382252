.search {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	text-align: center;
}

.searchBox {
	width: 100%;
}

.getDetail {
	display: inline-block;
	background: #e65910;
	color: #fff;
	font-size: 14px;
	padding: 10px 20px;
	border-radius: 7px;
	box-shadow: 0px 2px 5px 2px #999;
}

.CamPic {
	position: fixed;
	background: #fff;
	height: 100vh;
	width: 100vw;
	z-index: 12;
	bottom: 0;
}

.CamPic video {
	width: 100%;
	margin-top: 40%;
	border-radius: 10px;
}

.CamPic button {
	background: #ec6060;
	color: #fff;
	font-size: 16px;
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	margin-top: 10px;
	box-shadow: 0px 0px 3px 2px #ddd;
}

.srimg {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.srimg img {
	margin-top: -16px;
}