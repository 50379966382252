.header_icon span {
	width: 30px;
	height: 2px;
	background: #828282;
	margin-bottom: 4px;
	position: relative;
	display: block;
}

.header {
	position: static;
	top: 0;
	background: #fff;
	height: 60px;
	align-items: center;
	width: 100%;
}

.header_menu {
	width: 100%;
}

.header_icon {
	position: absolute;
	top: 10px;
	right: 20px;
	z-index: 8;
}