@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.login {
	text-align: center;
	position: absolute;
	width: 300px;
	height: 380px;
	background: #fff;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 15px;
	box-shadow: 0px 0px 10px 2px #aaa;
	padding: 20px 10px;
}

input {
	padding: 10px 15px;
	width: 75%;
	border: none;
	box-shadow: 0px 0px 2px #aaa;
	border-radius: 5px;
	margin-bottom: 20px;
	border-bottom: 3px solid #eee;
}

.login_logo {
	margin-bottom: 50px;
}

.btn-grad {
	background-image: linear-gradient(to right, #D31027 0%, #EA384D 51%, #D31027 100%);
	margin: 10px;
	padding: 15px 45px;
	text-align: center;
	text-transform: uppercase;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	box-shadow: 0 0 20px #eee;
	border-radius: 10px;
	border: none;
	font-weight: 800;
}

.btn-grad:hover {
	background-position: right center;
	/* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}

.switch {
	position: absolute;
	bottom: 20px;
	cursor: pointer;
	color: #D31027;
	margin: 0px auto;
	left: 0;
	right: 0;
	font-size: 14px;

}

.err {
	color: red;
	font-size: 12px;
}

.btn {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #78a2d2;
	color: #fff;
	font-size: 14px;
	border-radius: 5px;
	box-shadow: 0px 0px 6px 0px #999;
}

.btn img {
	width: 40px;
}

.check {
	font-size: 14px;
	margin-bottom: 20px;
	margin-top: -20px;
}