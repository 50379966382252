
.carBox{
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 60px;
}
   .country-wrap{
	position: absolute;
	transform: scale(0.6);
	height: 100%;
	width: 200%;
	margin-left: -30%;
	top: -15px;
   }
   .car {
	position: absolute;
	bottom: 0px;
	left: 100px;
	animation: myCar 6s linear infinite;
   }
   @keyframes myCar {
	0% {
	  left: -25%;
	}
	100% {
	  left: 100%;
	}
   }
   .car-body {
	border-bottom: 24px solid #d1352b;
	height: 0;
	top: 10px;
	width: 120px;
	position: relative;
	z-index: 1;
   }
   .car-body::before {
	content: "";
	display: inline-block;
	width: 30px;
	height: 24px;
	position: absolute;
	right: -5px;
	background: #d1352b;
	border-top-right-radius: 4px;
	z-index: 1;
   }
   .car-body::after {
	content: "";
	display: inline-block;
	width: 121px;
	border-bottom: 1px solid #942b25;
	border-right: 2px solid transparent;
	height: 0;
	z-index: 2;
	position: absolute;
   }
   .car-top-back {
	background: none repeat scroll 0 0 #4c4b4b;
	border-radius: 5px 0 0 0;
	height: 20px;
	left: 4px;
	position: absolute;
	top: -10px;
	width: 50px;
   }
   .car-top-back--window {
	background: #736f6f;
	content: "";
	height: 15px;
	width: 30px;
	position: absolute;
	z-index: 1;
	left: 12%;
	top: 3px;
	border-radius: 2px;
   }
   .car-top-back::before {
	position: absolute;
	content: "";
	height: 22px;
	width: 10px;
	background: #4c4b4b;
	left: -4px;
	top: -2px;
	border-top-left-radius: 90%;
   }
   .car-top-back::after {
	position: absolute;
	content: "";
	background: #4c4b4b;
	height: 5px;
	width: 60px;
	top: -3px;
	border-radius: 30%;
	left: 4px;
   }
   .car-top-front {
	position: absolute;
	height: 21px;
	width: 27px;
	border-left: 1px solid #892924;
	background: #dc4630;
	top: -11px;
	right: 48px;
	border-radius: 3px 0 0 0;
   }
   .car-top-front::before {
	content: "";
	position: absolute;
	background: #dc4630;
	height: 21px;
	width: 20px;
	right: -8px;
	transform: skew(34deg);
   }
   .car-top-front::after {
	content: "";
	position: absolute;
	background: #dc4630;
	height: 5px;
	width: 10px;
	right: -18px;
	bottom: 0;
	border-right: 1px solid #942b25;
   }
   .car-top-front--window {
	position: absolute;
	background: white;
	height: 14px;
	width: 17px;
	left: 4px;
	top: 3px;
   }
   .car-top-front--window::before {
	content: "";
	position: absolute;
	height: 14px;
	background: white;
	width: 10px;
	right: -7px;
	transform: skew(23deg);
	border-radius: 0 2px 2px 0;
   }
   .car-bonnot-front {
	position: absolute;
	top: 6px;
	right: 0;
	background: #dc4630;
	height: 5px;
	width: 30px;
	border-top-right-radius: 100%;
   }
   .car-tyre-cover--front {
	position: absolute;
	background: #4c4b4b;
	height: 5px;
	width: 21px;
	z-index: 2;
	right: 0;
	top: 13px;
   }
   .car-tyre-cover--front::before {
	content: "";
	position: absolute;
	background: #4c4b4b;
	height: 20px;
	width: 7px;
	transform: skew(-32deg);
	left: -10px;
	border-radius: 4px 0 0 0;
   }
   .car-tyre-cover--front::after {
	content: "";
	position: absolute;
	background: #4c4b4b;
	height: 5px;
	width: 9px;
	right: -8px;
	transform: skew(20deg);
	border-radius: 0 4px 1px 0;
   }
   .car-tyre-cover--back {
	position: absolute;
	background: #4c4b4b;
	height: 5px;
	width: 23px;
	left: 11px;
	top: 14px;
	z-index: 2;
   }
   .car-tyre-cover--back::before {
	content: "";
	position: absolute;
	background: #4c4b4b;
	height: 20px;
	width: 5px;
	transform: skew(-20deg);
	border-radius: 4px 0 0 0;
	left: -5px;
   }
   .car-tyre-cover--back::after {
	content: "";
	position: absolute;
	background: #4c4b4b;
	height: 20px;
	width: 6px;
	right: -8px;
	transform: skew(32deg);
	border-radius: 0 4px 0 0;
   }
   .car-tyre-cover--back--bottom {
	background: #4c4b4b;
	position: absolute;
	height: 8px;
	width: 17px;
	top: 12px;
	left: -23px;
	z-index: 1;
   }
   .car-gate {
	position: absolute;
	border: 1px solid #942b25;
	height: 18px;
	width: 36px;
	right: 38px;
	top: 10px;
	z-index: 2;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 2px;
   }
   .car-gate::before {
	content: "";
	position: absolute;
	background: #4c4b4b;
	height: 3px;
	width: 8px;
	top: 2px;
	left: 4px;
   }
   .car-stepney {
	background: #3f3f40;
	height: 10px;
	width: 6px;
	position: absolute;
	left: -6px;
	top: 13px;
   }
   .car-stepney::before {
	content: "";
	background: #3f3f40;
	height: 12px;
	width: 10px;
	position: absolute;
	left: -10px;
	top: -12px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
   }
   .car-stepney::after {
	content: "";
	background: #0d0c0d;
	height: 18px;
	width: 10px;
	position: absolute;
	left: -10px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
   }
   .car-tyre {
	border-radius: 50%;
	background: #3f3f40;
	height: 30px;
	width: 30px;
	z-index: 2;
	position: absolute;
	animation: tyre-rotate 1s linear infinite;
   }
   .car-tyre::before {
	content: "";
	background: #bdc2bd;
	border-radius: 50%;
	height: 18px;
	width: 18px;
	position: absolute;
	left: 6px;
	top: 6px;
   }
   .car-tyre--gap {
	background: #3f3f40;
	position: absolute;
	height: 4px;
	width: 2px;
	left: 14px;
	top: 8px;
	box-shadow: 0 9px 0 #3f3f40;
   }
   .car-tyre--gap::before {
	content: "";
	background: #3f3f40;
	position: absolute;
	height: 4px;
	width: 2px;
	left: 5px;
	top: 5px;
	box-shadow: 0 9px 0 #3f3f40;
	transform: rotate(90deg);
   }
   @keyframes tyre-rotate {
	0% {
	  transform: rotate(-360deg);
	}
	100% {
	  transform: rotate(0deg);
	}
   }
   .car-tyre-front {
	left: 9px;
	top: 20px;
   }
   .car-tyre-back {
	right: -4px;
	top: 20px;
	z-index: 2;
   }
   .car-shadow {
	bottom: -15px;
	box-shadow: -5px 10px 15px 3px #000000;
	left: -7px;
	position: absolute;
	width: 136px;
   }
   .car-base-axcel {
	position: absolute;
	background: black;
	width: 57px;
	height: 6px;
	right: 25px;
	top: 33px;
	z-index: 1;
	transform: rotate(-2deg);
   }
   .car-base-axcel::before {
	content: "";
	position: absolute;
	background: black;
	width: 17px;
	height: 7px;
	right: 85px;
	top: -2px;
	z-index: 1;
	transform: rotate(-2deg);
	border-bottom-left-radius: 100%;
   }
   .car-base-axcel::after {
	content: "";
	background: black;
	position: absolute;
	height: 10px;
	width: 13px;
	right: -39px;
	top: -6px;
	z-index: -1;
	border-bottom-right-radius: 6px;
   }
   .car-bumper-front {
	position: absolute;
	background: #4c4b4b;
	height: 10px;
	width: 14px;
	right: -16px;
	z-index: 1;
	top: 20px;
	border-radius: 0 3px 3px 0;
   }
   .car-bumper-front::before {
	content: "";
	background: #000000;
	height: 12px;
	width: 10px;
	position: absolute;
	left: -6px;
	transform: rotate(-37deg);
	top: 2px;
   }
   .car-inner-boundry {
	position: absolute;
	background: #000000;
	height: 15px;
	width: 35px;
	z-index: 1;
   }
   .car-inner-boundry--front {
	right: -6px;
	top: 18px;
	width: 36px;
   }
   .car-inner-boundry--front::before {
	content: "";
	background: #d1352b;
	height: 10px;
	width: 10px;
	position: absolute;
	left: -8px;
   }
   .car-inner-boundry--back {
	left: 8px;
	top: 19px;
   }
   .car-inner-boundry--back::before {
	content: "";
	background: #d1352b;
	height: 10px;
	width: 10px;
	position: absolute;
	right: -6px;
   }